import React from "react";
import ReactDOM from "react-dom";
import App from './App';
import Video from "./components/video/Video";
import { AppProvider } from './context/State';
import classes from "./styles/styles.scss";
import './styles/index.css';


ReactDOM.render(
  <AppProvider>
      <App />
      <Video />
  </AppProvider>,
  document.getElementById("root"),
);