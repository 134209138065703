import React, { useState, useContext } from "react";
import {useNavigate} from 'react-router-dom';
import { AppContext } from '../../context/State';
import "./CardRoutines.css";

const CardRoutines = ({ data, subtitle }) => {
  const history = useNavigate();
  const { uDispatch } = useContext(AppContext);

  const open = () => {
    uDispatch({ type: "SET_WORKOUT",  payload: data });
    history('/workout');
  }

  return (

    <div
      className="card_routines_subcontainer"
      onClick={() => open()}
    >
      <div className="card_routines_img_container">
        <img
          src={`https://storage.googleapis.com/salsafitt/images/rutinas/${data.thumb}`}
          alt={data.entrenador}
          className="card_routines_img_img"
        />
      </div>
      <div className="card_routines_coach_container">
        <h2>{data.title}</h2>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}

export default CardRoutines;