import React, {useContext} from "react";
import { Link } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { AppContext } from '../../context/State';

import "./Home.css";

let strings = new LocalizedStrings({
  es: {
    titulos: {
      arnes_salsafitt: "Strength Fitness",
      arnes_sub: "Strength Fitness",
      mejores_materiales: "LOS MEJORES MATERIALES",
      ajuste_perfecto: "AJUSTE PERFECTO",
      colores: "COLORES",
      comodidad: "COMODIDAD",
      innovacion: "INNOVACIÓN",
      eficiencia: "EFICIENCIA",
    },
    parrafos: {
      arnes_salsafitt: "¡Haz de tu vida una experiencia extraordinaria!",
      mejores_materiales:
        "Está hecho con material de neopreno, nylon y velcros de la más alta calidad, acompañado de diversas bandas de látex resistentes que se conectan entre sí con ganchos y aros en plástico resistente, garantizando la seguridad de las personas.",
      ajuste_perfecto_p1:
        "El cinturón principal de color negro con medidas de 150cm de largo por 11cm de ancho ofrece un ajuste ergonómico a cualquier tipo de cintura y de manera cómoda garantiza la flexibilidad necesaria para la actividad física, acompañado de dos tiras de nylon para sujetar el cinturón completamente.",
      ajuste_perfecto_p2:
        "Adicionalmente cuenta con dos correas negras ergonómicas para poner en las muñecas de las manos de 24cm de largo por 8cm de ancho y dos correas para ser utilizadas en la zona del cuello de ambos pies y así conectar las bandas resistentes entre todas las extremidades del cuerpo.",
      colores:
        "Las 14 bandas resistentes vienen en tres colores: naranja, verde y negro; con tres capacidades de 10, 15 y 20 libras y en tres diferentes medidas 53cm, 35cm y 17cm. Todas con ganchos en ambas puntas para conectar con armonía el sistema ergonómico del cuerpo en cualquier estatura y necesidades físicas de entrenamiento de las personas.",
      comodidad:
        "El arnés viene empacado en una bolsa de 36cm de alto por 33cm de ancho en poliéster con cuerdas en los extremos para utilizado a cualquier hora y en cualquier lugar, incluso llevándolo de viaje para darle continuidad a las rutinas de ejercicio.",
      innovacion:
        "El innovador arnés puede ser utilizado con varios propósitos: haciendo las rutinas de nuestra aplicación bailando y entrenando, también utilizándolo para rutinas diarias complementarias de gimnasio, inclusive con propósito de recuperación y fortalecimiento en procesos de fisioterapia.",
      eficiencia_p1:
        "Con nuestro arnés se aceleran los procesos físicos. Las cargas en las bandas elásticas con su resistencia fortalecen los músculos de todo el cuerpo con su adecuado uso, graduando la fuerza con los diferentes tamaños y grosores de cada banda, interactuando con las rutas de conexión entre las extremidades del cuerpo de manera creativa.",
      eficiencia_p2:
        "Recomendamos su uso con la guía de los instructores de nuestra comunidad, quienes son profesionales del bienestar colectivo y tienen el criterio adecuado para ofrecer eficientes y divertidas rutinas.",
    },
    btn: "¡CÓMPRALO AQUÍ!",
  },
  en: {
    titulos: {
      arnes_salsafitt: "Strength Fitness",
      arnes_sub: "Strength Fitness",
      mejores_materiales: "THE BEST MATERIALS",
      ajuste_perfecto: "FITTING",
      colores: "COLORS",
      comodidad: "CONVENIENCE",
      innovacion: "INNOVATION",
      eficiencia: "EFFICIENCY",
    },
    parrafos: {
      arnes_salsafitt: "Make your life an extraordinary experience!",
      mejores_materiales:
        "It is made of the highest quality neoprene, nylon, and Velcro. It includes several durable latex bands connected to each other by hooks and resistant plastic rings to ensure your safety.",
      ajuste_perfecto_p1:
        "The 150 cm long x 11 cm wide black belt, accompanied by two nylon straps that fully fasten it, offers an ergonomic and comfortable fit to any waist size, and guarantees the flexibility necessary for physical activity. It also has two 24 cm long x 8 cm wide ergonomic black straps for the wrists and two straps for the ankles, thus connecting all the limbs of the body.",
      ajuste_perfecto_p2: "",
      colores:
        "The 14 bands come in three colors (orange, green, and black), three resistance levels (10, 15, and 20 pounds), and three sizes (53, 35, and 17 cm). They all have hooks on both ends to harmoniously connect the ergonomic system of the body and suit any user height or workout need.",
      comodidad:
        "The harness comes in a 36 cm high x 33 cm wide polyester drawstring bag for you to use it anytime and anywhere. You can even travel with it to continue with your workout routines.",
      innovacion:
        "The innovative harness can be used for several purposes: doing the dancing and training routines in our application, complementing other daily gym routines, and even for recovery and strengthening exercises in physical therapy.",
      eficiencia_p1:
        "Our harness accelerates fitness processes. The loads on the resistance bands strengthen the muscles of the whole body when used properly, adjusting the force with different band sizes and thicknesses, and interacting with the connections between limbs creatively.",
      eficiencia_p2:
        "We recommend that you use it under the guidance of our instructors, who are collective well-being professionals and have good judgment to provide you with fun, efficient routines.",
    },
    btn: "BUY NOW!",
  },
});

const Intro = () => {
  const { language } = useContext(AppContext);
  strings.setLanguage(language);


  return (
    <>
    <div className="paddingTop_container">
        <section className="harness_principal_container">
          <div className="home_principal_info_container">
            <h2>{strings.titulos.arnes_salsafitt}</h2>
            <p>{strings.parrafos.arnes_salsafitt}</p>
            <Link to="/shop" className="harness_principal_btn_container">
              <h3>{strings.btn}</h3>
            </Link>

            <img
              className="home_logo_img"
              src="http://strengthfitness.fit/images/logo_afaa.png"
              alt=""
            />

          </div>
          <div className="harness_principal_img_container">
            <img
              className="harness_principal_img_img"
              src="http://strengthfitness.fit/images/arnes.jpg"
              alt={strings.titulos.arnes_salsafitt}
            />
          </div>
        </section>
        </div>
    </>
  );
};

export default Intro;