import firebase from 'firebase';
  
const firebaseConfig = {
  apiKey: "AIzaSyBH2AiEWmE5bzncUpyBtjkk-KeUo4I2dPo",
  authDomain: "salsafitt-1a3f2.firebaseapp.com",
  databaseURL: "https://salsafitt-1a3f2.firebaseio.com",
  projectId: "salsafitt-1a3f2",
  storageBucket: "salsafitt-1a3f2.appspot.com",
  messagingSenderId: "909800239043",
  appId: "1:909800239043:web:c0b9e42a1089763bc3d776",
  measurementId: "G-EGEHCN7H72"
};
    
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const database = firebase.database();
const providerFb = new firebase.auth.FacebookAuthProvider();

export {auth, database, providerFb};