import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { BiHide, BiShowAlt } from "react-icons/bi";
import { FaUser, FaEnvelope, FaLock } from "react-icons/fa";
import SF_Bottomless from "../../assets/sf_logo_bottomless.png";
import strings_account from "./Strings";
import { AppContext } from '../../context/State';
import { signUp } from "../../context/Actions";
import "./Account.css";

const Login = ({ doc_title }) => {
  const { uDispatch, language } = useContext(AppContext);
  const history = useNavigate();
  const [error, setError] = useState(false);
  const [msg, setMsg] = useState("");
  const [showHide, setShowHide] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  strings_account.setLanguage(language);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const continuar = () => {
    setError(false);
    if(name === "" || email === "" || password === ""){
      setError(true);
      setMsg(strings_account.check_name);
      return;
    }

    signUp(uDispatch, name, email, password, history, setError, setMsg);
  };

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <div className="account_maincontainer">

        <Link to="/" className="account_link_home">
          <img
            className="account_logo_salsafitt"
            src={SF_Bottomless}
            alt=""
          />
        </Link>

        

        <section className="account_form_container">

            <section className="account_titile_container">
              <h3>{strings_account.intro_registro}</h3>
            </section>

            <div className="account_input_container">
              <FaUser size={20} color="#fff" />
              <input
                className="account_input"
                placeholder={strings_account.placeholder.name}
                type="text"
                onChange={(e) => setName(e.target.value)}
                required
              ></input>
            </div>

            <div className="account_input_container">
              <FaEnvelope size={20} color="#fff" />
              <input
                className="account_input"
                placeholder={strings_account.placeholder.email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                required
              ></input>
            </div>

            <div className="account_input_container">
              <FaLock size={20} color="#fff" />
              <input
                className="account_input"
                placeholder={strings_account.placeholder.contraseña}
                type={showHide ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                required
              ></input>
              <div className="account_form_showHide" onClick={() => { showHide ? setShowHide(false) : setShowHide(true); }} >
                {showHide ? (
                  <BiHide size={20} className="account_form_showHide_icon" />
                ) : (
                  <BiShowAlt size={20} className="account_form_showHide_icon" />
                )}
              </div>
            </div>

            

            
            {error ? 
              (<div className="account_form_error">
                <p>{msg}</p>
              </div> ) : null }
            
            <section className="account_btns_container">
              <div className="account_btn_container account_btn_color_1" onClick={() =>  continuar() }>
                <p>{strings_account.registrarse}</p>
              </div>
            </section>

          <section className="account_noAccount">
            <Link to="/login"  className="link_noline">
              <p> {strings_account.tiene_cuenta} <b>{strings_account.iniciar_sesion}</b></p>
            </Link>
          </section>

          <div className="account_form_terminos">
            <a href={strings_account.url} target="_blank">{strings_account.ver_terminos}</a>
          </div>

        </section>
      </div>
    </>
  );
};

export default Login;
