import React, { useState, useEffect, useContext } from "react";
import Helmet from "react-helmet";
import LocalizedStrings from "react-localization";
import Header from "../../components/header/Header";
import Topbar from "../../components/topbar/Topbar";
import { AppContext } from '../../context/State';
import { formatNumber } from '../../utils/Global';
import "./Cart.css";

let strings = new LocalizedStrings({
  es: {
    titulos: {
      encabezado: "¡Haz de tu vida una experiencia extraordinaria!",
      arnes_salsafitt: "ARNÉS Strength Fitness",
      resumen: "RESUMEN DEL PEDIDO",
      formulario: "ENVÍO",
      subtotal: "Subtotal",
      envio: "Envío",
      total: "TOTAL",
    },
    parrafos: {
      arnes_p1: "Trabaja todo el cuerpo.",
      arnes_p2: "Incluye 6 meses de suscripción para disfrutar de todo el contenido.",
      arnes_p3: "Envíos a todo Colombia.",
      gratis: "Gratis",
      iva: "(incluyendo IVA)",
    },
    placeholder: {
      nombre_completo: "Nombre completo",
      email: "Correo electrónico",
      numero_identificacion: "Número de identificación",
      direccion: "Dirección de envío",
      ciudad: "Ciudad",
      numero_celular: "Número de celular",
      cuentanos: "Cuéntanos de tu experiencia y formación...",
    },
    procesar_compra: "Procesar compra",
    completar_compra: "Completar compra",
    btn_enviar: "Enviar",
  },
  en: {
    titulos: {
      encabezado: "Make Your Life An Extraordinary Experience!",
      arnes_salsafitt: "Strength Fitness HARNESS",
      resumen: "ORDER SUMMARY",
      formulario: "SHIPPING",
      subtotal: "Subtotal",
      envio: "Shipment",
      total: "TOTAL",
    },
    parrafos: {
      arnes_p1: "Works the whole body.",
      arnes_p2: "Includes 6 months subscription to enjoy all the content.",
      arnes_p3: "Shipments to all of Colombia.",
      gratis: "Free",
      iva: "(including TAXES)",
    },
    placeholder: {
      nombre_completo: "Name",
      email: "E-mail",
      numero_identificacion: "ID number",
      direccion: "Shipping Address",
      ciudad: "City",
      numero_celular: "Mobile",
      cuentanos: "Tell us about your experience and education",
    },
    procesar_compra: "Process purchase",
    completar_compra: "Complete purchase",
    btn_enviar: "Send",
  },
});

const Cart = ({ doc_title }) => {
  const { language } = useContext(AppContext);
  strings.setLanguage(language);
  
  const [precio, setPrecio] = useState(199000);
  const [total, setTotal] = useState(199000);
  const [quantity, setQuantity] = useState(1);
  const [procesar, setProcesar] = useState(false);
  let res;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const qLess = () => {
    if (quantity === 1) {
      res = 1;
    } else {
      res = quantity - 1;
    }
    setQuantity(res);
    setTotal(precio * res);
  };

  const qPlus = () => {
    res = quantity + 1;
    setQuantity(res);
    setTotal(precio * res);
  };

  const openForm = () => {
    window.scrollTo(0, 0);
    setProcesar(true);
  }

  const enviar = () => {
    var submitButton = document.getElementById('submit-button');
    submitButton.click();
  }

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header />

      <div className="paddingTop_container">

        <Topbar title={strings.titulos.encabezado} url={"http://strengthfitness.fit/images/salsafitt-cart.jpg"}/>

        <section className="cart_info_container">

          {!procesar ? (
            <div className="cart_subcontainer">
            <section className="cart_data_container">
              <div className="cart_data_img_container">
                <img
                  className="cart_data_img_img"
                  src="http://strengthfitness.fit/images/shop_arnes.jpg"
                  alt={strings.titulos.arnes_salsafitt}
                />
              </div>
              <div className="cart_data_title_container">
                <h4>{strings.titulos.arnes_salsafitt}</h4>
              </div>
            </section>
            <section className="cart_data_p_container">
              <p className="cart_data_p"> {strings.parrafos.arnes_p1} </p>
              <p className="cart_data_p"> {strings.parrafos.arnes_p2} </p>
              <p className="cart_data_p"> {strings.parrafos.arnes_p3} </p>
            </section>

            <section className="cart_data_unit_container">
              <div className="cart_data_unit_container">
                <h4>COP ${formatNumber(precio)}</h4>
              </div>
              <div className="cart_quantity_container">
                <section className="cart_quantity_less" onClick={() => qLess()}>
                  <b>-</b>
                  <div className="cart_quantity_save" />
                </section>
                <div className="cart_quantity_quantity">{quantity}</div>
                <section className="cart_quantity_plus" onClick={() => qPlus()}>
                  <b>+</b>
                  <div className="cart_quantity_save" />
                </section>
              </div>
              <h4>
                <small>Subtotal: </small>COP ${formatNumber(total)}
              </h4>
            </section>
          </div>
          ) : (
            <div className="cart_subcontainer">
              <h4>{strings.titulos.formulario}</h4>
              <section className="coaches_joinUs_knowBetter_container">
                <form id="payulatam" className="coaches_form_container" method="post" action="https://salsafitt.com/checkout/">
                  <input name="nombre" placeholder={strings.placeholder.nombre_completo}  type="text" required/>
                  <input name="direccion" placeholder={strings.placeholder.direccion}  type="text" required />
                  <input name="ciudad" placeholder={strings.placeholder.ciudad}  type="text" required />
                  <input name="celular" placeholder={strings.placeholder.numero_celular}  type="number" minlength="10" maxlength="10" required />
                  <input name="email" placeholder={strings.placeholder.email} type="email" required />
                  <input name="totalcompra" type="hidden"  value={total} ></input>
                  <input name="totalcantidad" type="hidden"  value={quantity} ></input>
                  <button name="registro_compra" type="submit" hidden id="submit-button"></button>
                </form>
              </section>
            </div>
          )}
          
          


          <div className="cart_summary_container">
            <h4>{strings.titulos.resumen}</h4>
            <section className="cart_summary_subcontainer">
              <p>
                {strings.titulos.subtotal} ({quantity})
              </p>
              <p>COP ${formatNumber(total)}</p>
            </section>
            <section className="cart_summary_subcontainer">
              <p>{strings.titulos.envio}</p>
              <p>{strings.parrafos.gratis}</p>
            </section>
            <section className="cart_summary_subcontainer">
              <div>
                <h5>{strings.titulos.total}</h5>
                <p>{strings.parrafos.iva}</p>
              </div>
              <p>COP ${formatNumber(total)}</p>
            </section>

            {!procesar ? (
              <div className="cart_summary_btn cart_summary_btn_color_1" onClick={() => openForm()}>
                {strings.procesar_compra}
              </div>
            ):(
              <div className="cart_summary_btn cart_summary_btn_color_2" onClick={() => enviar()}>
                {strings.completar_compra}
              </div>
            )}

          </div>

        </section>
      </div>
    </>
  );
};

export default Cart;
