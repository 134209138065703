import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { AppContext } from '../../context/State';
import "./Home.css";

let strings = new LocalizedStrings({
  es: {
    ver_mas: "Ver a todos en la",
    titulos: {
      ser_entrenador: "CONVIÉRTETE EN NUESTRO ENTRENADOR",
      conocerte_mejor: "QUEREMOS CONOCERTE MEJOR",
    },
    parrafos: {
      ser_entrenador:
        "Certifícate como un entrenador especializado en las rutinas Strength Fitness.",
      ser_entrenador_completo:
        "Certifícate como un entrenador especializado en las rutinas Strength Fitness, donde obtendrás beneficios económicos y con proyección dentro de nuestra comunidad.",
      conocerte_mejor: "Por favor envíanos la siguiente información:",
    },
    placeholder: {
      nombre_completo: "Nombre completo",
      email: "Correo electrónico",
      numero_identificacion: "Número de identificación",
      direccion: "Dirección de residencia",
      ciudad: "Ciudad",
      numero_celular: "Número de celular",
      cuentanos: "Cuéntanos de tu experiencia y formación...",
    },
    btn_registrarse: "Registrarse",
    btn_enviar: "Enviar",
  },
  en: {
    ver_mas: "See them all in the",
    titulos: {
      ser_entrenador: "BECOME OUR COACH",
      conocerte_mejor: "WE WANT TO KNOW YOU BETTER",
    },
    parrafos: {
      ser_entrenador: "Become a certified Strength Fitness workout trainer.",
      ser_entrenador_completo:
        "Get certified as a trainer specialized in Strength Fitness routines. You will have great economic benefits and excellent growth prospects within our community",
      conocerte_mejor: "Please provide the following information:",
    },
    placeholder: {
      nombre_completo: "Name",
      email: "E-mail",
      numero_identificacion: "ID number",
      direccion: "Home address",
      ciudad: "City",
      numero_celular: "Mobile",
      cuentanos: "Tell us about your experience and education",
    },
    btn_registrarse: "Sign up",
    btn_enviar: "Send",
  },
});

export const Coaches = ({ isHome = false }) => {
  const { language } = useContext(AppContext);
  strings.setLanguage(language);
  
  return (
    <>
      <section className="coaches_header_container"  >
        <div className="coaches_header_container_text"  >
          <h2 style={{ margin: isHome ? "3rem 0" : "1rem" }}>
            {strings.titulos.ser_entrenador}
          </h2>
          <h4>{strings.parrafos.ser_entrenador}</h4>
         </div>

        <Link to="/coaches" className="coaches_headerHome_link" >
          {strings.btn_registrarse}
        </Link>
      </section>
    </>
  );
}

export default Coaches;