/**
 * SF
 * jorgereyes@8rios.com
 * jorgereyesdev@hotmail.com
 * www.8rios.com
 * @flow
*/


export const pad = (d) => {
  return (d < 10) ? '0' + d.toString() : d.toString();
}


export const formatDateString = (date) => {
  var dias = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sábado'];
  var meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  var dateObj = new Date(date);
  var month = dateObj.getMonth();
  var day = dateObj.getDate();
  var year = dateObj.getFullYear();
  var dia = dateObj.getDay();
  var stringdate = dias[dia]+", "+day + " " +  meses[month] + " " + year;
  return stringdate;
}

export const formatNumber = (num) => {
  num +='';
  var separador= ".";
  var sepDecimal= ',';
  var splitStr = num.split('.');
  var splitLeft = splitStr[0];
  var splitRight = splitStr.length > 1 ? sepDecimal + splitStr[1] : '';
  var regx = /(\d+)(\d{3})/;
  while (regx.test(splitLeft)) {
    splitLeft = splitLeft.replace(regx, '$1' + separador + '$2');
  }
  return splitLeft +splitRight;
}