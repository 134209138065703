import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";

import LocalizedStrings from "react-localization";
import Header from "../../components/header/Header";
import Topbar from "../../components/topbar/Topbar";
import CardDance from "../../components/carddance/CardDance";
import { AppContext } from '../../context/State';
import { getCapsulas } from "../../context/Actions";
import "./Dance.css";

let strings = new LocalizedStrings({
  es: {
    titulos: {
      ver_mas: "Ver más",
      musica: "¡VAMOS A BAILAR!",
    },
    subtitulos: {
      musica: "¡Vive la vida bailando!",
    },
  },
  en: {
    titulos: {
      ver_mas: "View more",
      musica: "LET'S DANCE!",
    },
    subtitulos: {
      musica: "Live your life dancing!",
    },
  },
});


const Dance = ({ doc_title }) => {
  const { capsulas, uDispatch, language } = useContext(AppContext);
  strings.setLanguage(language);

  useEffect(() => {
    getCapsulas(uDispatch, language);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header />

      <div className="paddingTop_container">
        <Topbar title={strings.subtitulos.musica} url={"http://strengthfitness.fit/images/salsafitt-dance.jpg"}/>
        <section className="routines_container_title">
          <h2 className="home_title_h2">{strings.titulos.musica}</h2>
        </section>
      </div>

      <section className="dance_container music_container_padd">
        {capsulas.map((data, index) => {
          return (
            <CardDance key={index} data={data} subtitle={strings.ver}/>
          );
        })}
      </section>
    </>
  );
};

export default Dance;