import React, {useContext, useEffect} from "react";
import { Helmet } from "react-helmet";
import LocalizedStrings from "react-localization";
import { AppContext } from '../../context/State';
import DivDownload from "../../components/footer/DivDownload";
import Header from "../../components/header/Header";
import bg_app from "../../assets/banner_app.jpg";
import "./PageApp.css";


let strings = new LocalizedStrings({
  es: {
    infoApp_parrafo:
      "Entrena con el Arnés Strength Fitness y únete a nuestra comunidad de bienestar colectivo.",
  },
  en: {
    infoApp_parrafo:
      "Train with the Strength Fitness Harness and join our community of collective wellness.",
  },
});



const PageApp = ({ doc_title }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header />
      <div className="paddingTop_container">
        <DownloadApp />
      </div>
    </>
  );
};

export default PageApp;

export const DownloadApp = () => {
  const { language } = useContext(AppContext);
  strings.setLanguage(language);

  return (
    <>
      <section className="app_downloadSpace">
        <div className="app_coverImage_container">
          <img
            src={bg_app}
            alt=""
            className="app_coverImage_img"
          />
        </div>
        <section className="app_ilustrator">
          <div className="app_app_casing">
            <div className="app_ilustrationApp_container">
              <img
                src="http://strengthfitness.fit/images/iphone-chat.png"
                alt=""
                className="app_ilustrationApp_img"
              />
            </div>
            <div className="app_infoApp_container">
              <section className="app_infoApp_subcontainer">
                <h2>Strength Fitness App</h2>
                <p>{strings.infoApp_parrafo}</p>
              </section>
            </div>
          </div>
          <section className="app_getApp_container">
            <div className="app_getApp_subcontainer">
              <DivDownload
                link="https://apps.apple.com/co/app/salsafitt/id1332015642"
                icon="appstore"
                width_container="20"
              />
              <DivDownload
                link="https://play.google.com/store/apps/details?id=com.salsafitt&hl=es_CO&gl=US"
                icon="googleplay"
                width_container="20"
              />
            </div>
          </section>
        </section>
      </section>
    </>
  );
};