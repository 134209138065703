import React from "react";
import "./Topbar.css";


const Topbar = ({ title, border=60, url }) => {

  return (
    <section className="topbar_titleSpace_container" style={{'borderBottomRightRadius': border, 'backgroundImage': `url(${url})` }}>
      <h2 className="topbar_titleSpace_title">
        {title}
      </h2>
    </section>
  );
};

export default Topbar;