import React, { useContext } from "react";
import Carousel from 'react-elastic-carousel';
import CardCarousel from "../cardcarousel/CardCarousel";
import { AppContext } from '../../context/State';
import "./Menu.css";

const Menu = () => {
  const { menu } = useContext(AppContext);

  const breakPoints = [
    // { width: 1, itemsToShow: 1 },
    // { width: 550, itemsToShow: 2 },
    { width: 890, itemsToShow: 3, enableSwipe:true },
    { width: 900, itemsToShow: 7, enableSwipe:false },
  ];

  return (
    <div className="carousel_container">
    <Carousel breakPoints={breakPoints} enableSwipe={false} disableArrowsOnEnd={false} >
      {menu.map((data, index) => {
        return (
          <CardCarousel key={index} data={data}/>
        );
      })}
    </Carousel>
    </div>
  );
};

export default Menu;