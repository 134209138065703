import React, { useState, useContext } from "react";
import { AppContext } from '../../context/State';
import { getRutinasTrainer } from "../../context/Actions";
import "./CardCarousel.css";

const CardCarousel = ({ data, subtitle }) => {
  const { uDispatch, language, trainer_id } = useContext(AppContext);

  const getRoutines = () => {
    getRutinasTrainer(uDispatch, data.id, language);
    uDispatch({ type: "SET_TRAINER_ID",  payload: data.id });
  }

  let color = 'rgba(255,255,255,0.3)';
  if(trainer_id === data.id){
    color = '#82bc43';
  }

  return (
    <div
      className="menu_item"
      onClick={() => getRoutines() }
    >
      <div className="">
        <img
          src={data.foto}
          alt={data.entrenador}
          className="menu_item_img"
        />
      </div>
      <div className="menu_item_text_container">
        <h2 style={{color:color}}>{data.nombre}</h2>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}

export default CardCarousel;