import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import LocalizedStrings from "react-localization";
import CardMusic from "../../components/cardmusic/CardMusic";
import { AppContext } from '../../context/State';
import { getMusica } from "../../context/Actions";
import "./Home.css";

let strings = new LocalizedStrings({
  es: {
    titulos: {
      ver_mas: "Ver más",
      musica: "NUESTRA MÚSICA",
    },
    subtitulos: {
      musica: "¡Cuando de música se trata, todos somos uno!",
    },
  },
  en: {
    titulos: {
      ver_mas: "View more",
      musica: "OUR MUSIC",
    },
    subtitulos: {
      musica: "When it comes to music, we are all one!",
    },
  },
});



const Music = () => {
  const { uDispatch, language, musica } = useContext(AppContext);
  strings.setLanguage(language);

  useEffect(() => {
    getMusica(uDispatch, language);
  }, []);
  
  return (
    <>
      <div className="music">
        <h2 className="home_title_h2">{strings.titulos.musica}</h2>
        <p className="home_title_p">{strings.subtitulos.musica}</p>
          <section className="home_cards_subcontainer">
            {musica.map((data, index) => {
              return (
                <CardMusic key={index} data={data} subtitle={strings.ver}/>
              );
            })}
          </section>
        <div className="music_btnMore_container">
            <Link to="/music"  className="music_btnMore_btn">
              <h3>{strings.titulos.ver_mas}</h3>
            </Link>
          </div>
      </div>
    </>
  );
}

export default Music;