import React, {useContext} from "react";
import { Link } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { AppContext } from '../../context/State';
import "./Home.css";

let strings = new LocalizedStrings({
  es: {
    banner_titulo: "Vive Una Experiencia Extraordinaria",
    banner_parrafo:
      "Entrena con el Arnés Strength Fitness y únete a nuestra comunidad de bienestar colectivo.",
    banner_obtenerArnes: "Cómpralo aquí",
    banner_obtenerApp: "Consigue la app",
    rutinas_titulo: "Rutinas",
    rutinas_parrafo: "SOMOS BIENESTAR COLECTIVO",
  },
  en: {
    banner_titulo: "Live an Extraordinary Experience",
    banner_parrafo:
      "Train with the Strength Fitness Harness and join our community of collective wellness.",
    banner_obtenerArnes: "Buy it here",
    banner_obtenerApp: "Get the app",
    rutinas_titulo: "Routines",
    rutinas_parrafo: "WE ARE A COLLECTIVE WELL-BEING COMMUNITY",
  },
});

const Intro = () => {
  const { language } = useContext(AppContext);
  strings.setLanguage(language);
  
  return (
    <>
      <section className="compra_sectionMedia_container compra_maincontainer">
          <div className="compra_sectionMedia_div_dark">
              <h1 className="home_banner_title">{strings.banner_titulo}</h1>
              <p className="home_banner_subtitle">{strings.banner_parrafo}</p>
              <div className="home_banner_btns_container">
                <Link
                  to="/shop"
                  className="home_banner_getHarnessBtn_container"
                >
                  <p className="home_banner_getHarnessBtn_text">
                    {strings.banner_obtenerArnes}
                  </p>
                </Link>
                <Link to="/app" className="home_banner_getAppBtn_container">
                  <p className="home_banner_getAppBtn_text">
                    {strings.banner_obtenerApp}
                  </p>
                </Link>
              </div>
          </div>
        </section>
    </>
  );
};

export default Intro;