const Reducers = (state, action) => {
  switch (action.type) {
    
    case "LOGIN_SUCCESS":
      return {
          ...state,
          isAuthenticated: !!localStorage.getItem('user_id'),
          user_id: localStorage.getItem("user_id"),
          email: localStorage.getItem("email"),
          loginError: false,
      };

    case "SIGN_OUT_SUCCESS":
      localStorage.clear();
      return {
          ...state,
          isAuthenticated: false,
          id: null,
          user_id: null,
          loginError: false,
      };

    case "SET_LANGUAGE":
        return {
          ...state,
          language: localStorage.getItem("language")
        };
        
    case "SET_MENU":
      return {
        ...state,
        menu: action.payload
      };

    
    case "SET_ALL_RUTINAS":
      return {
        ...state,
        rutinas: action.payload
      };

    
    case "SET_MUSICA":
      return {
        ...state,
        musica: action.payload
      };

    
    case "SET_CAPSULAS":
      return {
        ...state,
        capsulas: action.payload
      };

    

    case "SET_WORKOUT":
      return {
        ...state,
        workout: action.payload
      };
      
    
    case "SET_TRAINER_ID":
      return {
        ...state,
        trainer_id: action.payload
      };
      
    
    case "SET_ERROR":
        return {
          ...state,
          error: action.payload.error,
          message: action.payload.message
        };

    default:
      return state;
  }
};
export default Reducers;