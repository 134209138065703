import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import LocalizedStrings from "react-localization";
import CardRoutines from "../../components/cardroutines/CardRoutines";
import { AppContext } from '../../context/State';
import { getRutinasRand } from "../../context/Actions";
import "./Home.css";

let strings = new LocalizedStrings({
  es: {
    rutinas_titulo: "Rutinas",
    rutinas_parrafo: "SOMOS BIENESTAR COLECTIVO",
    ver: "Ver rutina",
    elegir_entrenador: "Elige un entrenador para ver sus rutinas...",
    ver_mas: "Ver más",
  },
  en: {
    rutinas_titulo: "Routines",
    rutinas_parrafo: "WE ARE A COLLECTIVE WELL-BEING COMMUNITY",
    ver: "See routine",
    elegir_entrenador: "Choose a trainer to see your routines...",
    ver_mas: "View more",
  },
});


const Routines = () => {
  const { state, uDispatch, language } = useContext(AppContext);
  strings.setLanguage(language);

  useEffect(() => {
    getRutinasRand(uDispatch, language);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="home_routines">
        <h2 className="home_title_h2">{strings.rutinas_titulo}</h2>
        <p className="home_title_p">{strings.rutinas_parrafo}</p>

        <section className="home_cards_subcontainer">
          {state.rutinas.map((data, index) => {
            return (
              <CardRoutines key={index} data={data} subtitle={strings.ver}/>
            );
          })}
        </section>

        <div className="music_btnMore_container">
          <Link to="/routines" className="music_btnMore_btn">
            <h3>{strings.ver_mas}</h3>
          </Link>
        </div>

      </div>
    </>
  );
};

export default Routines;