import React, { createContext, useReducer } from 'react';
import usersReducer from './Reducers'

const initialState = {
    
    language: localStorage.getItem("language") || 'es',
    isAuthenticated: !!localStorage.getItem('user_id'),
    user_id: localStorage.getItem("user_id") || '',
    email: localStorage.getItem("email") || '',

    menu : [],
    rutinas : [],
    musica : [],
    capsulas : [],
    workout : {},
    trainer_id : 'andres',

    users: {
        users: [],
        totalItems: 0,
        totalPages: 0
    },

    loading: false,
    error: false,
    message: "",
}

export const AppContext = createContext(initialState);
export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(usersReducer, initialState);
    return (
        <AppContext.Provider value={{
            state: state,
            isAuthenticated: state.isAuthenticated,
            user_id: state.user_id,
            email: state.email,
            trainer_id: state.trainer_id,
            menu: state.menu,
            workout: state.workout,
            musica: state.musica,
            capsulas: state.capsulas,
            language: state.language,
            uDispatch: dispatch
        }}>
            {children}
        </AppContext.Provider>
    );
}