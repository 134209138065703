import React, {useContext} from "react";
import LocalizedStrings from "react-localization";
import { AppContext } from '../../context/State';
import "./Home.css";

let strings = new LocalizedStrings({
  es: {
    arnesSalsaFitt_titulo: "ARNÉS Strength Fitness",
    arnesSalsaFitt_parrafo: "HAZ DE TU VIDA UNA EXPERIENCIA EXTRAORDINARIA",
    innovador_titulo: "INNOVADOR",
    innivador_parrafo:
      "El innovador arnés puede ser utilizado con varios propósitos.",
    comodidad_titulo: "COMODIDAD",
    comodidad_parrafo:
      "El arnés viene empacado en una bolsa de 36cm de alto por 33cm de ancho con cuerdas en los extremos.",
    materiales_titulo: "LOS MEJORES MATERIALES",
    materiales_parrafo:
      "Está hecho con material de neopreno, nylon y velcros de la más alta calidad.",
    ajuste_titulo: "AJUSTE",
    ajuste_parrafo:
      "El cinturón principal de color negro ofrece un ajuste ergonómico a cualquier tipo de cintura.",
  },
  en: {
    arnesSalsaFitt_titulo: "Strength Fitness HARNESS",
    arnesSalsaFitt_parrafo: "MAKE YOUR LIFE AN EXTRAORDINARY EXPERIENCE",
    innovador_titulo: "INNOVATIVE",
    innivador_parrafo:
      "The innovative harness can be used for various purposes.",
    comodidad_titulo: "COMFORT",
    comodidad_parrafo:
      "The harness comes packed in a 36cm high by 33cm wide bag with strings at the ends.",
    materiales_titulo: "THE BEST MATERIALS",
    materiales_parrafo:
      "It is made with the highest quality neoprene, nylon and velcro material.",
    ajuste_titulo: "ADJUSTMENT",
    ajuste_parrafo:
      "The black main belt offers an ergonomic fit to any type of waist.",
  },
});

const Harness = () => {
  const { language } = useContext(AppContext);
  strings.setLanguage(language);

  const characteristics = [
    {
      img: "http://strengthfitness.fit/images/salsafitt-arnes-sm-1.jpg",
      title: `${strings.innovador_titulo}`,
      paragraph: `${strings.innivador_parrafo}`,
    },
    {
      img: "http://strengthfitness.fit/images/salsafitt-arnes-sm-2.jpg",
      title: `${strings.comodidad_titulo}`,
      paragraph: `${strings.comodidad_parrafo}`,
    },
    {
      img: "http://strengthfitness.fit/images/salsafitt-arnes-sm-3.jpg",
      title: `${strings.materiales_titulo}`,
      paragraph: `${strings.materiales_parrafo}`,
    },
    {
      img: "http://strengthfitness.fit/images/salsafitt-arnes-sm-4.jpg",
      title: `${strings.ajuste_titulo}`,
      paragraph: `${strings.ajuste_parrafo}`,
    },
  ];

  return (
    <>
      <section className="home_harnessInfo_container">
        <div>
          <h2 className="home_title_h2">
            {strings.arnesSalsaFitt_titulo}
          </h2>
          <p className="home_title_p">
            {strings.arnesSalsaFitt_parrafo}
          </p>
        </div>
        <div className="home_harnessInfo_subcontainer">
          {characteristics.map((data, index) => {
            return (
                <section key={index} className="home_characteristics_container">
                  <div className="home_characteristics_img_container">
                    <img
                      src={data.img}
                      alt={data.title}
                      className="home_characteristics_img_img"
                    />
                  </div>
                  <div className="home_characteristics_info_container">
                    <h3>{data.title}</h3>
                    <p>{data.paragraph}</p>
                  </div>
                </section>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Harness;
