import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { BiHide, BiShowAlt } from "react-icons/bi";
import Header from "../../components/header/Header";
import SF_Bottomless from "../../assets/sf_logo_bottomless.png";
import strings_account from "./Strings";
import { AppContext } from '../../context/State';
import { signOut } from "../../context/Actions";
import "./Account.css";

const Profile = ({ doc_title }) => {
  const { language, uDispatch } = useContext(AppContext);
  const history = useNavigate();
  strings_account.setLanguage(language);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const [showHide, setShowHide] = useState(false),
    [email, setEmail] = useState(""),
    [password, setPassword] = useState("");

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header />

      <div className="account_maincontainer">

        <Link to="/" className="account_link_home account_log_padding">
          <img
            className="account_logo_salsafitt"
            src={SF_Bottomless}
            alt=""
          />
        </Link>

        <section className="account_titile_container">
          <h3>{strings_account.perfil}</h3>
        </section>
        <section className="account_form_container">
          {/* <div className="account_form"> */}

            <div className="account_btn_container account_btn_color_1" onClick={() => signOut(uDispatch, history)}>
              <p>{strings_account.cerrar_sesion}</p>
            </div>
          {/* </div> */}

        </section>
      </div>
    </>
  );
};

export default Profile;