import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LocalizedStrings from "react-localization";

import Login from "./pages/account/Login";
import Signup from "./pages/account/Signup";
import Profile from "./pages/account/Profile";
import Home from "./pages/home/Home";
import PageApp from "./pages/app/PageApp";
import Harness from "./pages/harness/Harness";
import Routines from "./pages/routines/Routines";
import Workout from "./pages/workout/Workout";
import Coaches from "./pages/coaches/Coaches";
import Music from "./pages/music/Music";
import Dance from "./pages/dance/Dance";
import Cart from "./pages/cart/Cart";

import Footer from "./components/footer/Footer";
import NotFound from "./components/notfound/NotFound";
import WhatsApp from "./components/whatsapp/WhatsApp";

let strings = new LocalizedStrings({
  es: {
    titulo_inicio: "Strength Fitness | Inicio",
    titulo_arnes: "Strength Fitness | Arnés",
    titulo_rutinas: "Strength Fitness | Rutinas",
    titulo_workout: "Strength Fitness | Entrenamiento",
    titulo_entrenadores: "Strength Fitness | Entrenadores",
    titulo_musica: "Strength Fitness | Música",
    titulo_baile: "Strength Fitness | Baile",
    titulo_app: "Strength Fitness | App",
    sesion: "Strength Fitness | Iniciar Sesión",
    registrarse: "Strength Fitness | Registrarse",
    perfil: "Strength Fitness | Perfil",
    shop: "Strength Fitness | Tienda",
  },
  en: {
    titulo_inicio: "Strength Fitness | Home",
    titulo_arnes: "Strength Fitness | Harness",
    titulo_rutinas: "Strength Fitness | Routines",
    titulo_workout: "Strength Fitness | Workout",
    titulo_entrenadores: "Strength Fitness | Coaches",
    titulo_musica: "Strength Fitness | Music",
    titulo_baile: "Strength Fitness | Dance",
    titulo_app: "Strength Fitness | App",
    sesion: "Strength Fitness | Log In",
    registrarse: "Strength Fitness | Sign Up",
    perfil: "Strength Fitness | Profile",
    shop: "Strength Fitness | Shop",
  },
});

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home doc_title={strings.titulo_inicio} />} />
        <Route path="/harness" element={<Harness doc_title={strings.titulo_arnes} />} />
        <Route path="/routines" element={<Routines doc_title={strings.titulo_rutinas} />} />
        <Route path="/workout" element={ <Workout doc_title={strings.titulo_workout} />} />
        <Route path="/coaches" element={<Coaches doc_title={strings.titulo_entrenadores} />} />
        <Route path="/music" element={<Music doc_title={strings.titulo_musica} />} />
        <Route path="/dance" element={<Dance doc_title={strings.titulo_baile} />} />
        <Route path="/app" element={<PageApp doc_title={strings.titulo_app} />} />
        <Route path="/login" element={<Login doc_title={strings.sesion} />} />
        <Route path="/signup" element={<Signup doc_title={strings.registrarse} />} />
        <Route path="/profile" element={<Profile doc_title={strings.perfil} />} />
        <Route path="/shop" element={<Cart doc_title={strings.shop} />} />
        <Route path="*" element={<NotFound doc_title="¡Oops! | Página no encontrada" />} />
      </Routes>
      <WhatsApp phone={573053884330} />
      <Footer />
    </Router>
  );
};

export default App;