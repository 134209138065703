import React, {useEffect, useContext, useState} from "react";
import LocalizedStrings from "react-localization";
import {useNavigate} from 'react-router-dom';
import { Helmet } from "react-helmet";
import { MdOutlineCable } from "react-icons/md";
import { BiTime } from "react-icons/bi";
import { AiOutlineFire, AiOutlineHeart } from "react-icons/ai";
import { sendEvent } from "../../utils/Events";
import Header from "../../components/header/Header";
import { AppContext } from '../../context/State';
import "./Workout.css";

let strings = new LocalizedStrings({
  es: {
    subtitulos: {
      nivel: 'Nivel',
      level: 'Básico',
      conectar_bandas: 'Conecta las bandas para esta rutina',
      parrafo_libre: 'Recuerda que tú eres libre de escoger las bandas de acuerdo a su intensidad de 10, 15 o 20 lb y también al largo de cada una.',
      puntos_conexion: 'A continuación te presentamos los puntos de conexión para este ejercicio:',
      entrenador: 'Entrenador',
      tutorial_general: 'Tutorial general para ponerte el arnés',
      tutorial_ver: 'Ver tutorial',
      empezar_rutina: '¡EMPEZAR RUTINA!',
      arnes_salsafitt: 'Arnés Strength Fitness',
      duracion: 'Duración',
      duracion_texto: 'Minutos',
      calorias: 'Calorías',
      frec_cardiaca: 'Frecuencia cardíaca',
      tutorial_url: '1GOfSfSsXn8',
    },
  },
  en: {
    subtitulos: {
      nivel: 'Level',
      level: 'Basic',
      conectar_bandas: 'Connect the bands for this routine',
      parrafo_libre: 'Remember that you are free to choose the bands according to their intensity of 10, 15 or 20 lb and also long with each one.',
      puntos_conexion: 'Here are the connection points for this exercise:',
      entrenador: 'Trainer ',
      tutorial_general: 'General tutorial for putting on the harness',
      tutorial_ver: 'See tutorial',
      empezar_rutina: 'START ROUTINE!',
      arnes_salsafitt: 'Strength Fitness harness',
      duracion: 'Duration',
      duracion_texto: 'Minutes',
      calorias: 'Calories',
      frec_cardiaca: 'Heart rate',
      tutorial_url: 'PbrAFcYVnas',
    }
  },
});


const Workout = ({ doc_title }) => {
  const { workout, language, isAuthenticated } = useContext(AppContext);
  const history = useNavigate();
  strings.setLanguage(language);
  

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const openTutorial = () => {
    sendEvent("modal", { type: strings.subtitulos.tutorial_url });
  }

  const openRutina = () => {
    console.log(isAuthenticated);
    if(isAuthenticated){
      sendEvent("modal", { type: workout.video });
    }else{
      history('/login');
    }
  }

  let coach_img = `https://storage.googleapis.com/salsafitt/images/tutorial/${workout.tutorial_foto}`;
  let link = `https://www.youtube.com/embed/${workout.trail}?version=3&rel=0&controls=0&showinfo=0&autoplay=1&mute=0&loop=1`;

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header />
      
      <div className="paddingTop_container">

        <section className="routine_inframe_container">
          <div className="routine_inframe_subcontainer">
            <iframe
              id="videotrailer"
              className="routine_videotrailer"
              src={link}
              rel="0"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </section>


        <section className="routine_main">

          <div className="routine_info_container">
            <div className="routine_info_subcontainer">
              <h3>
                {strings.subtitulos.nivel}: {strings.subtitulos.level}
              </h3>
              <h2>{workout.title}</h2>
              <p>{workout.descripcion}</p>
            </div>
            <div className="routine_coach_container">
              <h2>
                {strings.subtitulos.entrenador} - {workout.entrenador}
              </h2>
              <div className="routine_coach_img_container">
                <img
                  src={coach_img}
                  alt={workout.entrenador}
                  className="routine_coach_img_img"
                />
              </div>
            </div>
          </div>

          <div className="routine_info_container">
            <div className="routine_info_subcontainer">
              <h3>{strings.subtitulos.conectar_bandas}</h3>
              <p style={{ fontWeight: "bold" }}>
                {strings.subtitulos.parrafo_libre}
              </p>
              <p>{strings.subtitulos.puntos_conexion}</p>
              <p> {workout.tutorial_content} </p>              
            </div>
            <div className="routine_tutorial_container">
              <p>{strings.subtitulos.tutorial_general}</p>
              <div className="routine_tutorial_btn_container" onClick={() => openTutorial()}>
                <p>{strings.subtitulos.tutorial_ver}</p>
              </div>
            </div>
          </div>

          <div className="routine_info_container routine_lineColor">
            <section className="routine_moreInfo_container">
              <div className="routine_moreInfo_icon">
                <MdOutlineCable size={35} />
              </div>
              <div className="routine_moreInfo_info">
                <h3>{strings.subtitulos.arnes_salsafitt}</h3>
                <p>{workout.arnes}</p>
              </div>
            </section>
            <section className="routine_moreInfo_container">
              <div className="routine_moreInfo_icon">
                <BiTime size={35} />
              </div>
              <div className="routine_moreInfo_info">
                <h3>{strings.subtitulos.duracion}</h3>
                <p>
                  {workout.duracion} {strings.subtitulos.duracion_texto}
                </p>
              </div>
            </section>
            <section className="routine_moreInfo_container">
              <div className="routine_moreInfo_icon">
                <AiOutlineFire size={35} />
              </div>
              <div className="routine_moreInfo_info">
                <h3>{strings.subtitulos.calorias}</h3>
                <p>{workout.calorias}</p>
              </div>
            </section>
            <section className="routine_moreInfo_container">
              <div className="routine_moreInfo_icon">
                <AiOutlineHeart size={35} />
              </div>
              <div className="routine_moreInfo_info">
                <h3>{strings.subtitulos.frec_cardiaca}</h3>
                <p>
                  {workout.frecuencia}
                </p>
              </div>
            </section>
          </div>

          <div className="routine_info_container">
            <div className="routine_start_btn_container" onClick={() => openRutina()}>
              <p>{strings.subtitulos.empezar_rutina}</p>
            </div>
          </div>
          
        </section>
      </div>
    </>
  );
};

export default Workout;
