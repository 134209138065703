import React, {useContext, useEffect} from "react";
import { Helmet } from "react-helmet";
import LocalizedStrings from "react-localization";
import Header from "../../components/header/Header";
import Topbar from "../../components/topbar/Topbar";
import { AppContext } from '../../context/State';
import "./Coaches.css";


let strings = new LocalizedStrings({
  es: {
    ver_mas: "Ver a todos en la",
    titulos: {
      ser_entrenador: "CONVIÉRTETE EN NUESTRO ENTRENADOR",
      conocerte_mejor: "QUEREMOS CONOCERTE MEJOR",
    },
    parrafos: {
      ser_entrenador:
        "Certifícate como un entrenador especializado en las rutinas Strength Fitness.",
      ser_entrenador_completo:
        "Certifícate como un entrenador especializado en las rutinas Strength Fitness, donde obtendrás beneficios económicos y con proyección dentro de nuestra comunidad.",
      conocerte_mejor: "Por favor envíanos la siguiente información:",
    },
    placeholder: {
      nombre_completo: "Nombre completo",
      email: "Correo electrónico",
      numero_identificacion: "Número de identificación",
      direccion: "Dirección de residencia",
      ciudad: "Ciudad",
      numero_celular: "Número de celular",
      cuentanos: "Cuéntanos de tu experiencia y formación...",
    },
    btn_registrarse: "Registrarse",
    btn_enviar: "Enviar",
  },
  en: {
    ver_mas: "See them all in the",
    titulos: {
      ser_entrenador: "BECOME OUR COACH",
      conocerte_mejor: "WE WANT TO KNOW YOU BETTER",
    },
    parrafos: {
      ser_entrenador: "Become a certified Strength Fitness workout trainer.",
      ser_entrenador_completo:
        "Get certified as a trainer specialized in Strength Fitness routines. You will have great economic benefits and excellent growth prospects within our community",
      conocerte_mejor: "Please provide the following information:",
    },
    placeholder: {
      nombre_completo: "Name",
      email: "E-mail",
      numero_identificacion: "ID number",
      direccion: "Home address",
      ciudad: "City",
      numero_celular: "Mobile",
      cuentanos: "Tell us about your experience and education",
    },
    btn_registrarse: "Sign up",
    btn_enviar: "Send",
  },
});

const Coaches = ({ doc_title }) => {
  const { language } = useContext(AppContext);
  strings.setLanguage(language);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header />

      <div className="paddingTop_container">
        <Topbar title={strings.parrafos.ser_entrenador} url={"http://strengthfitness.fit/images/salsafitt-coaches.jpg"}/>

        <section className="coaches_joinUs_container">

          <div className="coaches_joinUs_subcontainer">
            <section className="coaches_joinUs_info_container">
              <h2>{strings.titulos.ser_entrenador}</h2>
              <p>{strings.parrafos.ser_entrenador_completo}</p>
              <img
              className="home_logo_img"
              src="http://strengthfitness.fit/images/logo_afaa.png"
              alt=""
            />
            </section>
            <section className="coaches_joinUs_img_container">
              <img
                className="coaches_joinUs_img_img"
                src="http://strengthfitness.fit/images/entrenador.jpg"
                alt={strings.titulos.ser_entrenador}
              />
            </section>
          </div>

          <div className="coaches_joinUs_subcontainer coaches_flexStart">

            <section className="coaches_joinUs_knowBetter_container_1">
              <h3>{strings.titulos.conocerte_mejor}</h3>
              <p>{strings.parrafos.conocerte_mejor}</p>
            </section>

            <section className="coaches_joinUs_knowBetter_container">
              <form className="coaches_form_container">
                <input placeholder={strings.placeholder.nombre_completo} type="text" required/>
                <input placeholder={strings.placeholder.email} type="email" required/>
                <input placeholder={strings.placeholder.numero_identificacion} type="number" required/>
                <input placeholder={strings.placeholder.direccion} type="text" required/>
                <input placeholder={strings.placeholder.ciudad} type="text" required/>
                <input placeholder={strings.placeholder.numero_celular}  type="number" required/>
                <textarea placeholder={strings.placeholder.cuentanos} cols={40} rows="5" required></textarea>
                <button>{strings.btn_enviar}</button>
              </form>
            </section>
            
          </div>
        </section>
      </div>
    </>
  );
};

export default Coaches;