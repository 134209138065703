import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../header/Header";
import SF_Bottomless from "../../assets/sf_logo_bottomless.png";
import "./NotFound.css";

const NotFound = ({ doc_title }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header />
      <div className="paddingTop_container">
        <div className="notfound_container">

          <Link to="/" className="account_link_home">
            <img
              className="account_logo_salsafitt"
              src={SF_Bottomless}
              alt=""
            />
          </Link>

          <h1>Página no encontrada</h1>
        </div>
      </div>
    </>
  );
};

export default NotFound;
