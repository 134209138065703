import {auth, database, providerFb} from './Firebase';

export const setLoading = (dispatch, status) => {
  // dispatch({ type: "SET_LOADING", payload: status });
}

export const logIn = (dispatch, email, password, history, setError) => {
  auth.signInWithEmailAndPassword(email, password)
    .then(result => {
        let user_id = email.replace(/\./g, "_");
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("email", email);
        dispatch({ type: "LOGIN_SUCCESS", payload: { } });
        history("/");
    })
    .catch(error => {
      console.log(error)
      setError(true);
    });
}

export const signUp = (dispatch, name, email, password, history, setError, setMsg) => {
  auth.createUserWithEmailAndPassword(email, password)
    .then(result => {
        let user_id = email.replace(/\./g, "_");
        localStorage.setItem("user_id", user_id);
        localStorage.setItem("email", email);
        dispatch({ type: "LOGIN_SUCCESS", payload: { } });
        history("/");
    })
    .catch(error => {
      console.log(error)
      setMsg("Error");
      setError(true);
    });
}

export const loginFb = (dispatch, email, password, history, setError) => {
  auth.signInWithPopup(providerFb)
    .then(result => {
      console.log(result)
        // let user_id = email.replace(/\./g, "_");
        // localStorage.setItem("user_id", user_id);
        // localStorage.setItem("email", email);
        // dispatch({ type: "LOGIN_SUCCESS", payload: { } });
        // history("/");
    })
    .catch(error => {
      setError(true);
    });
}

export const signOut = (dispatch, history) => {
  auth.signOut();
  localStorage.removeItem("user_id");
  localStorage.removeItem("email");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history("/");
}

export const getMenu = (dispatch) => {
  const nameRef = database.ref('production/profesores/');
  nameRef.once("value", (snapshot) => {
    const data = [];
    snapshot.forEach((doc) => {
        var obj = doc.toJSON();
        obj['key'] = doc.key;
        data.push(obj);
    });
    dispatch({
      type: "SET_MENU",
      payload: data
    });
  });
};

export const getRutinasRand = (dispatch, language) => {
  let rand = Math.floor(Math.random() * 13);
  let trainers = ['andres','aura','brayan','daniela','danielam','george','lorena','lulu','margaret','melanie','paola','valeria','viviana'];
  let trainer = trainers[rand];
  const nameRef = database.ref('production/rutinas/' + language + '/' + trainer).limitToLast(8);
  nameRef.once("value", (snapshot) => {
    const data = [];
    snapshot.forEach((doc) => {
        var obj = doc.toJSON();
        obj['key'] = doc.key;
        obj['trainer'] = trainer;
        data.push(obj);
    });
    dispatch({
      type: "SET_ALL_RUTINAS",
      payload: data
    });
  });
};

export const getRutinasTrainer = (dispatch, trainer, language) => {
  const nameRef = database.ref('production/rutinas/' + language + '/' + trainer);
  nameRef.once("value", (snapshot) => {
    const data = [];
    snapshot.forEach((doc) => {
        var obj = doc.toJSON();
        obj['key'] = doc.key;
        obj['trainer'] = trainer;
        data.push(obj);
    });
    dispatch({
      type: "SET_ALL_RUTINAS",
      payload: data
    });
  });
};

export const getMusica = (dispatch, language) => {
  const nameRef = database.ref('production/musica/');
  nameRef.once("value", (snapshot) => {
    const data = [];
    snapshot.forEach((doc) => {
        var obj = doc.toJSON();
        data.push(obj);
    });
    dispatch({
      type: "SET_MUSICA",
      payload: data
    });
  });
};

export const getCapsulas = (dispatch, language) => {
  const nameRef = database.ref('production/capsulas/' + language);
  nameRef.once("value", (snapshot) => {
    const data = [];
    snapshot.forEach((doc) => {
        var obj = doc.toJSON();
        data.push(obj);
    });
    dispatch({
      type: "SET_CAPSULAS",
      payload: data
    });
  });
};