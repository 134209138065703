import React, { useState } from "react";
import { AiOutlineWhatsApp, AiOutlineSend } from "react-icons/ai";
import LocalizedStrings from "react-localization";
//----------------------------------------------------
import "./WhatsApp.css";
//----------------------------------------------------
let strings = new LocalizedStrings({
  es: {
    nombre: "Nombre",
    subtitulos: {
      elegir: "Elegir texto predeterminado",
      msm_final: "",
      tu_mensaje: "O escribe tu mensaje",
      enviar: "Enviar",
    },
    labels: {
      tu_nombre: "Escribe tu nombre",
      obligatorio: "Obligatorio",
    },
    placeholder: {
      tu_nombre: "Tu nombre:",
    },
    intro: "Hola, soy ",
    mas_info: "Y quiero más información acerca del Arnés Strength Fitness, por favor",
  },
  en: {
    nombre: "Name",
    subtitulos: {
      elegir: "Choose default text",
      msm_final: "",
      tu_mensaje: "Or write your message",
      enviar: "Send",
    },
    labels: {
      tu_nombre: "Write your name",
      obligatorio: "Required",
    },
    placeholder: {
      tu_nombre: "Your name:",
    },
    intro: "Hello, I'm ",
    mas_info: "And I want more information about the Strength Fitness Harness, please",
  },
});
//----------------------------------------------------

const WhatsApp = ({ phone }) => {
  const [dialogActive, setDialogActive] = useState(false),
    [customersName, setCustomersName] = useState("-"),
    [message, setMessage] = useState(""),
    [selectedMsm, setSelectedMsm] = useState(false);

  let customName = "";
  if (customersName.length > 25) {
    customName = `${customersName.slice(0, 25)}...`;
  } else {
    customName = customersName;
  }

  let msm = ``,
    preload_msm = `${strings.intro}, ${customersName}.%0A${strings.mas_info}`,
    customersName_msm = `${strings.intro}, ${customersName}.%0A${message}`,
    sendBtn = "",
    blockade = "";

  if (customersName.length > 1 && selectedMsm === true) {
    msm = preload_msm;
    sendBtn = "wpp_send_btn_container";
    blockade = "wpp_send_btn_container_divdisabled_no";
  } else if (customersName.length > 1 && message.length > 1) {
    msm = customersName_msm;
    sendBtn = "wpp_send_btn_container";
    blockade = "wpp_send_btn_container_divdisabled_no";
  } else {
    sendBtn = "wpp_send_btn_container wpp_send_btn_container_disabled";
    blockade = "wpp_send_btn_container_divdisabled";
  }
  let url = `https://api.whatsapp.com/send?phone=${phone}&text=${msm}`;

  const openWhatsApp = () => {
    window.open(`https://api.whatsapp.com/send?phone=${phone}&text=Hola, quiero más información acerca del Arnés Strength Fitness`);
  }

  

  return (
    <>
      <div
        className={dialogActive ? "wpp_dialog wpp_dialog_active" : "wpp_dialog"}
        onClick={() => setDialogActive(false)}
      />
      <div
        className={
          dialogActive
            ? "wpp_container wpp_container_dialogActive"
            : "wpp_container"
        }
        onClick={() => openWhatsApp()}
      >
        <section className="wpp_iconText_container">
          <h3 className="wpp_iconText_text">WhatsApp</h3>
          <AiOutlineWhatsApp size={25} className="wpp_iconText_icon" />
        </section>
        <section className="wpp_info_container">
          <div className="wpp_info_headerName_container">
            <h4>{strings.nombre}:</h4>
            <p>{customName}</p>
          </div>
          <form className="wpp_form_container">
            <label htmlFor="form_name">
              {strings.labels.tu_nombre}:{" "}
              <small style={{ fontSize: "1rem" }}>
                ({strings.labels.obligatorio})
              </small>
            </label>
            <input
              id="form_name"
              placeholder={strings.placeholder.tu_nombre}
              onChange={(e) => setCustomersName(e.target.value)}
            />
            <div
              className={
                selectedMsm
                  ? "wpp_info_preloadText_container wpp_info_preloadText_container_selected"
                  : "wpp_info_preloadText_container"
              }
            >
              <h6>{strings.subtitulos.elegir}...</h6>
              <p
                onClick={() =>
                  selectedMsm ? setSelectedMsm(false) : setSelectedMsm(true)
                }
              >
                {strings.intro}
                {customName}. {strings.mas_info}.
              </p>
            </div>
            <div className="wpp_info_preloadText_container">
              <h6>{strings.subtitulos.tu_mensaje}:</h6>
              <textarea
                cols={40}
                rows="5"
                onChange={(e) => setMessage(e.target.value)}
                onClick={() => setSelectedMsm(false)}
              ></textarea>
            </div>
          </form>
          <div className="wpp_send_container">
            <a href={url} target="_blank" rel="noreferrer" className={sendBtn}>
              <h6>{strings.subtitulos.enviar}</h6>
              <AiOutlineSend size={20} className="wpp_send_btn_icon" />
            </a>
            <div className={blockade} />
          </div>
        </section>
      </div>
    </>
  );
};

export default WhatsApp;
