import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";

import LocalizedStrings from "react-localization";
import Header from "../../components/header/Header";
import Topbar from "../../components/topbar/Topbar";
import CardMusic from "../../components/cardmusic/CardMusic";
import { AppContext } from '../../context/State';
import { getMusica } from "../../context/Actions";
import "./Music.css";

let strings = new LocalizedStrings({
  es: {
    titulos: {
      ver_mas: "Ver más",
      musica: "NUESTRA MÚSICA",
    },
    subtitulos: {
      musica: "¡Cuando de música se trata, todos somos uno!",
    },
  },
  en: {
    titulos: {
      ver_mas: "View more",
      musica: "OUR MUSIC",
    },
    subtitulos: {
      musica: "When it comes to music, we are all one!",
    },
  },
});

const Music = ({ doc_title }) => {
  const { musica, uDispatch, language } = useContext(AppContext);
  strings.setLanguage(language);

  useEffect(() => {
    getMusica(uDispatch, language);
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header />

      <div className="paddingTop_container">
        <Topbar title={strings.subtitulos.musica} url={"http://strengthfitness.fit/images/salsafitt-music.jpg"}/>
        <section className="routines_container_title">
          <h2 className="home_title_h2">{strings.titulos.musica}</h2>
        </section>
      </div>

      <section className="music_container music_container_padd">
        {musica.map((data, index) => {
          return (
            <CardMusic key={index} data={data} subtitle={strings.ver}/>
          );
        })}
      </section>
    </>
  );
};

export default Music;