import LocalizedStrings from "react-localization";

let strings_account = new LocalizedStrings({
    es: {
        intro_titulo: 'LA MEJOR COMUNIDAD DE BIENESTAR',
        intro_registro: 'Regístrate en Strength Fitness',
		intro_subtitulo: 'Cada detalle ha sido diseñado con el propósito de generar felicidad y salud como fórmula mágica de nuestro concepto de bienestar.',
        iniciar_sesion: 'Iniciar Sesión',
        iniciar_sesion_facebook: 'Continuar con Facebook',
        cerrar_sesion: 'Cerrar Sesión',
        registrarse: 'Continuar',
        placeholder: {
            name: 'Nombre completo',
            email: 'Correo electrónico',
            contraseña: 'Contraseña',
            confirmar: 'Confirmar contraseña',
        },
        olvido: '¿Has olvidado tu contraseña?',
        nuevo: '¿Eres nuevo en Strength Fitness?',
        registrate_ahora: 'Regístrate ahora.',
        tiene_cuenta: '¿Ya tienes una cuenta?',
        perfil: 'Mi Cuenta',
        invalido: 'Correo/contraseña inválido',
        ver_terminos: 'Al continuar, confirmas que aceptas nuestras Condiciones de Uso y nuestra Política de Privacidad.',
        url: 'https://salsafitt.com/privacidad/',
        check_name: 'Por favor ingresa todos los datos',
    }, en: {
        intro_titulo: 'THE BEST WELLNESS COMMUNITY',
        intro_registro: 'Sign up for Strength Fitness',
		intro_subtitulo: 'Every detail has been designed with the purpose of generating happiness and health as a magic formula for our concept of well-being.',
        iniciar_sesion: 'Sign In',
        iniciar_sesion_facebook: 'Login with Facebook',
        cerrar_sesion: 'Sign Out',
        registrarse: 'Continue',
        placeholder: {
            name: 'Full name',
            email: 'Email',
            contraseña: 'Password',
            confirmar: 'Confirm password',
        },
        olvido: 'Forgot password?',
        nuevo: 'New to Strength Fitness?',
        registrate_ahora: 'Sign up now.',
        tiene_cuenta: 'Do you already have an account?',
        perfil: 'My Account',
        invalido: 'Invalid email/password',
        ver_terminos: 'By continuing, you confirm that you accept our Terms of Use and our Privacy Policy.',
        url: 'https://salsafitt.com/privacy/',
        check_name: 'Please fill out the form',
    }
});

export default strings_account;