import React, {useContext, useEffect} from "react";
import { Helmet } from "react-helmet";
import LocalizedStrings from "react-localization";
import Header from "../../components/header/Header";
import Topbar from "../../components/topbar/Topbar";
import Menu from "../../components/menu/Menu";
import CardRoutines from "../../components/cardroutines/CardRoutines";
import { AppContext } from '../../context/State';
import { getRutinasTrainer, getMenu } from "../../context/Actions";
import "./Routines.css";

let strings = new LocalizedStrings({
  es: {
    rutinas_titulo: "Rutinas",
    rutinas_parrafo: "Somos Bienestar Colectivo",
    ver: "Ver rutina",
    elegir_entrenador: "Elige un entrenador para ver sus rutinas...",
  },
  en: {
    rutinas_titulo: "Routines",
    rutinas_parrafo: "We are a collective well-being community",
    ver: "See routine",
    elegir_entrenador: "Choose a trainer to see your routines...",
  },
});

const Routines = ({ doc_title }) => {
  const { state, uDispatch, language, trainer_id } = useContext(AppContext);
  strings.setLanguage(language);

  useEffect(() => {
    getRutinasTrainer(uDispatch, trainer_id, language);
    getMenu(uDispatch);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header />

      <div className="paddingTop_container white_bone">

        <section className="routines_container_menu">
          <div className="routines_subcontainer_menu">
            <h2 className="home_title_h2">{strings.rutinas_titulo}</h2> 
            <Menu title={strings.rutinas_parrafo}/>
          </div>
        </section>

        <section className="routines_container">
            {state.rutinas.map((data, index) => {
              return (
                <CardRoutines key={index} data={data} subtitle={strings.ver}/>
              );
            })}
          </section>
          
      </div>
    </>
  );
};

export default Routines;