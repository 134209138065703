import React, {useContext} from "react";
import { FaApple, FaGooglePlay } from "react-icons/fa";
import LocalizedStrings from "react-localization";
import { AppContext } from '../../context/State';
//--------------------------------------

let strings = new LocalizedStrings({
  es: {
    AppStore_textoSuperior: "Descárguelo desde la",
    AppStore_textoInferior: "App Store",
    GooglePlay_textoSuperior: "Consígelo en",
    GooglePlay_textoInferior: "Google Play",
  },
  en: {
    AppStore_textoSuperior: "Download on the",
    AppStore_textoInferior: "App Store",
    GooglePlay_textoSuperior: "Get it on",
    GooglePlay_textoInferior: "Google Play",
  },
});

const DivDownload = ({
  icon,
  link = "/",
  target = "_blank",
  width_container = 15,
  color = "#ffffff",
  bgcolor = "transparent",
}) => {

  const { language } = useContext(AppContext);
  strings.setLanguage(language);

  const widthContainer = `${width_container}rem`,
    heightContainer = `${width_container / 3}rem`,
    fontSizeTopText = `${width_container / 13.63}rem`,
    fontSizeBottomText = `${width_container / 7.5}rem`,
    iconSize = `${width_container / 0.6}`;
  let topText = "",
    bottomText = "";

  if (icon === "appstore") {
    topText = `${strings.AppStore_textoSuperior}`;
    bottomText = `${strings.AppStore_textoInferior}`;
    icon = <FaApple size={iconSize} style={{ color: color }} />;
  } else if (icon === "googleplay") {
    topText = `${strings.GooglePlay_textoSuperior}`;
    bottomText = `${strings.GooglePlay_textoInferior}`;
    icon = <FaGooglePlay size={iconSize} style={{ color: color }} />;
  }
  return (
    <a
      href={link}
      target={target}
      rel="noreferrer"
      className="footer_getTheApp_subcontainer"
      style={{
        width: widthContainer,
        height: heightContainer,
        backgroundColor: bgcolor,
      }}
    >
      <div className="footer_getTheApp_icon_container">{icon}</div>
      <div className="footer_getTheApp_info_container">
        <div className="footer_getTheApp_info_topSubcontainer">
          <p
            className="footer_getTheApp_info_text"
            style={{ fontSize: fontSizeTopText, color: color }}
          >
            {topText}
          </p>
        </div>
        <div className="footer_getTheApp_info_bottomSubcontainer">
          <p
            className="footer_getTheApp_info_text"
            style={{ fontSize: fontSizeBottomText, color: color }}
          >
            {bottomText}
          </p>
        </div>
      </div>
    </a>
  );
};

export default DivDownload;
