import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { DownloadApp } from "../app/PageApp";
import Header from "../../components/header/Header";
import Harness from "./Harness";
import Intro from "./Intro";
import Comprar from "./Comprar";
import Routines from "./Routines";
import Coaches from "./Coaches";
import Music from "./Music";


const Home = ({ doc_title }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <Header initialSolidColor={false} />

      <div>
        <Intro />
        <Comprar />
        <Harness />
        <DownloadApp />
        <Routines />
        <Coaches />
        <Music/>
      </div>
    </>
  );
};

export default Home;