import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { BiHide, BiShowAlt } from "react-icons/bi";
import { FaFacebookF, FaEnvelope, FaLock } from "react-icons/fa";
import SF_Bottomless from "../../assets/sf_logo_bottomless.png";
import strings_account from "./Strings";
import { AppContext } from '../../context/State';
import { logIn, loginFb } from "../../context/Actions";
import "./Account.css";

const Login = ({ doc_title }) => {
  const { uDispatch, language } = useContext(AppContext);
  const history = useNavigate();
  const [error, setError] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  strings_account.setLanguage(language);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  const loginUser = () => {
    setError(false);
    logIn(uDispatch, email, password, history, setError);
  };

  const loginFacebook= () => {
    setError(false);
    loginFb(uDispatch, history, setError);
  };

  return (
    <>
      <Helmet>
        <title>{doc_title}</title>
      </Helmet>
      <div className="account_maincontainer">

        <Link to="/" className="account_link_home">
          <img
            className="account_logo_salsafitt"
            src={SF_Bottomless}
            alt=""
          />
        </Link>

        

        <section className="account_form_container">

            <section className="account_titile_container">
              <h3>{strings_account.intro_titulo}</h3>
              <p>{strings_account.intro_subtitulo}</p>
            </section>

            <div className="account_input_container">
              <FaEnvelope size={20} color="#fff" />
              <input
                className="account_input"
                placeholder={strings_account.placeholder.email}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              ></input>
            </div>
            <div className="account_input_container">
              <FaLock size={20} color="#fff" />
              <input
                className="account_input"
                placeholder={strings_account.placeholder.contraseña}
                type={showHide ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
              <div className="account_form_showHide" onClick={() => { showHide ? setShowHide(false) : setShowHide(true); }} >
                {showHide ? (
                  <BiHide size={20} className="account_form_showHide_icon" />
                ) : (
                  <BiShowAlt size={20} className="account_form_showHide_icon" />
                )}
              </div>
            </div>

            

            <div className="account_form_forgot">
              <p>{strings_account.olvido}</p>
            </div>
            {error ? 
              (<div className="account_form_error">
                <p>{strings_account.invalido}</p>
              </div> ) : null }
            
            <section className="account_btns_container">
              <div className="account_btn_container account_btn_color_1" onClick={() =>  loginUser() }>
                <p>{strings_account.iniciar_sesion}</p>
              </div>

              {/* <div className="account_line"></div>

              <div className="account_btn_container account_btn_color_2" onClick={() =>  loginFacebook() }>
                <FaFacebookF size={20} color="#fff" style={{marginRight:5}}/>
                <p>{strings_account.iniciar_sesion_facebook}</p>
              </div> */}
            </section>

          <section className="account_noAccount">
            <Link to="/signup"  className="link_noline">
              <p> {strings_account.nuevo} <b>{strings_account.registrate_ahora}</b></p>
            </Link>
          </section>

        </section>
      </div>
    </>
  );
};

export default Login;
