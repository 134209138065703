import React, { useEffect, useState } from "react";
//import ReactPlayer from 'react-player'
import "./Video.css";

const Video = () => {
  const [modal, setModal] = useState(false);
  const [link, setLink] = useState();

  useEffect(() => {
    function call({ detail }) {
      action(detail.type);
    }
    document.addEventListener("modal", call);
    return () => document.removeEventListener("modal", call);
    // eslint-disable-next-line
  }, []);

  function action(video_id) {
    setLink(`https://www.youtube.com/embed/${video_id}?version=3&rel=0&controls=1&showinfo=0&autoplay=1&mute=0&loop=1`);
    setModal(true);
  }

  const close = () => {
    setModal(false);
  }

  return (
    <>
    {modal ? (
      <>
        <div className="music_modal_container">

          <div
            className="music_modal_container music_modal_close"
            onClick={() => close({})}
          />

          <section className="music_modal_video_container">
            <div className="music_modal_img_container">
              <iframe
                id="videotrailer"
                className="routine_videotrailer"
                src={link}
                rel="0"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
              />
            </div>
          </section>

        </div>
      </>
    ) : (
      null
    )}
    </>
  );
};

export default Video;