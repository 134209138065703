import React, { useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LocalizedStrings from "react-localization";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";
import { CgProfile, CgLogOut } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import { AppContext } from '../../context/State';
import SF_Bottomless from "../../assets/sf_logo_bottomless.png";
import "./Header.css";


let strings = new LocalizedStrings({
  es: {
    arnes: "Arnés",
    rutinas: "Rutinas",
    entrenadores: "Entrenadores",
    musica: "Música",
    dance: "Baile",
    app: "App",
    accesorios: "Accesorios",
    lenguaje: {
      español: "Español",
      ingles: "English",
    },
  },
  en: {
    arnes: "Harness",
    rutinas: "Routines",
    entrenadores: "Coaches",
    musica: "Music",
    dance: "Dance",
    app: "App",
    accesorios: "Accessories",
    lenguaje: {
      español: "Español",
      ingles: "English",
    },
  },
});


const Header = ({ initialSolidColor = true, sesionStarted = false }) => {
  const { language, uDispatch, isAuthenticated } = useContext(AppContext);
  const history = useNavigate();
  strings.setLanguage(language);

  const [menuMobile, setMenuMobile] = useState(false),
    [color, setColor] = useState(initialSolidColor),
    [login, setLogin] = useState(sesionStarted),
    [langActive, setLangActive] = useState(false);

  let location = useLocation(),
    path = location.pathname;

  let lang;
  if (language === "es") {
    lang = "Es";
  } else if (language === "en") {
    lang = "En";
  }

  const Lang = (lang) => {
    localStorage.setItem("language", lang);
    uDispatch({ type: "SET_LANGUAGE",  payload: lang });
  };

  const LinkHeader = ({ to = "/", title = "Home" }) => {
    let infoLink = "";

    if (path === to) {
      infoLink = "header_infoLinks_link header_infoLinks_link_active";
    } else {
      infoLink = "header_infoLinks_link";
    }
    return (
      <>
        <Link
          to={to}
          // onClick={() => {
          //   menuMobile(true);
          // }}
          className={infoLink}
        >
          <p>{title}</p>
        </Link>
      </>
    );
  };

  const changeColor = () => {
    if (window.scrollY <= 135 && initialSolidColor === false) {
      setColor(false);
    } else {
      setColor(true);
    }
  };
  window.addEventListener("scroll", changeColor);

  const actionMenu = () => {
    let isVisible = menuMobile ? "visible" : "hidden";
    // document.body.style.overflow = isVisible;
    menuMobile ? setMenuMobile(false) : setMenuMobile(true);
    if (window.scrollY <= 135 && initialSolidColor === false) {
      setColor(true);
    }
  };


  const checkAuth = () => {
    if(isAuthenticated){
      history('/profile');
    }else{
      history('/login');
    }
  }

  let buttonSecondary;
  if (menuMobile === true && window.screen.width >= 1200) {
    buttonSecondary = "header_menuCloseSecondary";
  } else {
    buttonSecondary = "buttonSecondary_disabled";
  }

  let cartLink = "header_buttons_icon";
  if (path === "/shop") {
    cartLink = "header_buttons_icon header_buttons_icon_active";
  }

  return (
    <header className={"header"}>
      <Link to="/" className="header_link_home" onClick={() => { }} >
        <img className="header_logo_salsafitt" src={SF_Bottomless} alt="" />
      </Link>


      <div className="header_info_container">
        <section
          className={
            menuMobile
              ? "header_infoLinks_container_active"
              : "header_infoLinks_container header_infoLinks_container_disabled"
          }
        >
          <div className="header_infoLinks_subcontainer">
            <LinkHeader title={strings.arnes} to="/harness" />
            <LinkHeader title={strings.rutinas} to="/routines" />
            <LinkHeader title={strings.entrenadores} to="/coaches" />
            <LinkHeader title={strings.musica} to="/music" />
            <LinkHeader title={strings.dance} to="/dance" />
            <LinkHeader title={strings.app} to="/app" />
          </div>
        </section>
      </div>


      <div className="header_login_container">

        <section className="header_buttons_container">
          {login ? (
            <div className="header_buttons_login">
              <CgLogOut size={25} className="header_buttons_icon" />
            </div>
          ) : (
            <div className="header_buttons_login" onClick={() => checkAuth()}>
              <CgProfile size={25} className="header_buttons_icon" />
            </div>
          )}
          <Link to="/shop"  className="header_buttons_shop">
            <AiOutlineShoppingCart size={25} className={cartLink} />
          </Link>
          <div
            className="header_buttons_language"
            onClick={() => setLangActive(true)}
          >
            <BiWorld size={25} className="header_buttons_icon" />
            <small>{lang}</small>
          </div>
          <div
            className={
              langActive
                ? "header_language_container header_language_container_active"
                : "header_language_container"
            }
            onClick={() => setLangActive(false)}
          >
            <div className="header_language_subcontainer">
              <section className="header_lang" onClick={() => Lang("es")}>
                <p>{strings.lenguaje.español}</p>
              </section>
              <section className="header_lang" onClick={() => Lang("en")}>
                <p>{strings.lenguaje.ingles}</p>
              </section>
            </div>
          </div>
        </section>


        <section className="header_buttonMenu_mobile" onClick={() => actionMenu()} >
          <div
            className={
              menuMobile
                ? "header_line_top header_line_top_close"
                : "header_line_top"
            }
          />
          <div
            className={
              menuMobile
                ? "header_line_middle header_line_middle_close"
                : "header_line_middle"
            }
          />
          <div
            className={
              menuMobile
                ? "header_line_bottom header_line_bottom_close"
                : "header_line_bottom"
            }
          />
        </section>
        
      </div>


    </header>
  );
};

export default Header;
