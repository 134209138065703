import React, { useContext }  from "react";
import LocalizedStrings from "react-localization";
import { FiFacebook, FiYoutube, FiInstagram, FiMail } from "react-icons/fi";
import SF_Bottomless from "../../assets/sf_logo_bottomless.png";
import { Link } from "react-router-dom";
import DivDownload from "./DivDownload";
import { sendEvent } from "../../utils/Events";
import { AppContext } from '../../context/State';
import "./Footer.css";


let strings = new LocalizedStrings({
  es: {
    parrafo_social: "Cada detalle ha sido diseñado con el propósito de generar felicidad y salud como fórmula mágica de nuestro concepto de bienestar.",
    titulo_salsafitt: "Strength Fitness",
    salsafitt_quees: "¿Qué es?",
    salsafitt_arnes: "Arnés",
    salsafitt_rutinas: "Rutinas",
    salsafitt_musica: "Música",
    titulo_soporte: "Soporte",
    soporte_tutorial: "Tutorial",
    soporte_contacto: "Contacto",
    soporte_politica: "Política de Privacidad",
    soporte_mejorar: "Ayúdanos a Mejorar",
    titulo_arnes: "Arnés",
    arnes_parrafo_uno: "Con nuestro arnés se aceleran los procesos físicos.",
    arnes_parrafo_dos: "Recomendamos su uso con la guía de los instructores de nuestra comunidad, quienes son profesionales del bienestar colectivo y tienen el criterio adecuado para ofrecer eficientes y divertidas rutinas.",
    obtenerApp: "Consigue la aplicación Strength Fitness",
    acerca_url: 'nFYlErpKGPg',
    tutorial_url: '1GOfSfSsXn8',
    politica: 'privacidad',
  },
  en: {
    parrafo_social: "Every detail has been designed to bring happiness and health as part of our magic formula for well-being.",
    titulo_salsafitt: "Strength Fitness",
    salsafitt_quees: "What is it?",
    salsafitt_arnes: "Harness",
    salsafitt_rutinas: "Routines",
    salsafitt_musica: "Music",
    titulo_soporte: "Support",
    soporte_tutorial: "Tutorial",
    soporte_contacto: "Contact",
    soporte_politica: "Privacy Policy",
    soporte_mejorar: "Help us to improve",
    titulo_arnes: "Harness",
    arnes_parrafo_uno: "Our harness accelerates fitness processes.",
    arnes_parrafo_dos: "We recommend that you use it under the guidance of our instructors, who are collective well-being professionals and have good judgment to provide you with fun, efficient routines.",
    obtenerApp: "Get the Strength Fitness app",
    acerca_url: 'nFYlErpKGPg',
    tutorial_url: 'PbrAFcYVnas',
    politica: 'privacy',
  },
});

const Footer = () => {
  const { language, uDispatch } = useContext(AppContext);
  strings.setLanguage(language);

  const LinksFooter = ({ to = "/", title = "Home", target = false }) => {
    target ? (target = "_blank") : (target = "_top");
    return (
      <>
        <Link to={to} className="footer_links_link" >
          <h4>{title}</h4>
        </Link>
      </>
    );
  };

  const openVideo = (value) => {
    sendEvent("modal", { type: value });
  }

  const openPolitica= () => {
    window.open(`https://salsafitt.com/${strings.politica}`);
  }

  return (
    <>
      <div className="footer">
        <section className="footer_info_container">
          <div className="footer_info_subcontainer">
            <section className="footer_social_container">

              <Link to="/">
                <img src={SF_Bottomless} alt="" className="footer_social_logo" />
              </Link>

              <p className="footer_text_lightColor footer_paragraph">
                {strings.parrafo_social}
              </p>
              <div className="footer_social_icons_container">
                <a
                  href="https://www.facebook.com/Salsafitt/"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_social_icons_link"
                >
                  <FiFacebook size={25} className="footer_social_icons_icon" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UC4rEGx_tb0tIYEVezeM11ew/featured"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_social_icons_link"
                >
                  <FiYoutube size={25} className="footer_social_icons_icon" />
                </a>
                <a
                  href="https://www.instagram.com/salsafitt/"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_social_icons_link"
                >
                  <FiInstagram size={25} className="footer_social_icons_icon" />
                </a>
                <a
                  href="mailto:contacto@salsafitt.com"
                  target="_blank"
                  rel="noreferrer"
                  className="footer_social_icons_link"
                >
                  <FiMail size={25} className="footer_social_icons_icon" />
                </a>
              </div>
            </section>
            <section className="footer_links_container">
              <div className="footer_links_subcontainer">
                <h3 className="footer_links_title">
                  {strings.titulo_salsafitt}
                </h3>

                <div className="footer_links_link footer_txt_link" onClick={() => openVideo(strings.acerca_url)}>
                  <h4>{strings.salsafitt_quees}</h4>
                </div>

                <LinksFooter to="/harness" title={strings.salsafitt_arnes} />
                <LinksFooter to="/routines" title={strings.salsafitt_rutinas} />
                <LinksFooter to="/music" title={strings.salsafitt_musica} />
              </div>
              <div className="footer_links_subcontainer">
                <h3 className="footer_links_title">{strings.titulo_soporte}</h3>
                <div className="footer_links_link footer_txt_link" onClick={() => openVideo(strings.tutorial_url)}>
                  <h4>{strings.soporte_tutorial}</h4>
                </div>
                <LinksFooter to="/contacto" title={strings.soporte_contacto} />

                <div className="footer_links_link footer_txt_link" onClick={() => openPolitica()}>
                  <h4>{strings.soporte_politica}</h4>
                </div>
                
                <LinksFooter to="/contacto" title={strings.soporte_mejorar} />
              </div>
            </section>
            <section className="footer_infoHarness_container">
              <div className="footer_infoHarness_subcontainers">
                <h3 className="footer_infoHarness_title">
                  {strings.titulo_arnes}
                </h3>

                <div className="footer_infoHarness_subcontainer">
                <p className="footer_text_lightColor footer_paragraph">
                  {strings.arnes_parrafo_uno}
                </p>
                <p className="footer_text_lightColor footer_paragraph">
                  {strings.arnes_parrafo_dos}
                </p>
                </div>

              </div>
            </section>
          </div>
          <section className="footer_getTheApp">
            <h3>{strings.obtenerApp}</h3>
            <section className="footer_getTheApp_container">
              <DivDownload
                link="https://apps.apple.com/co/app/salsafitt/id1332015642"
                icon="appstore"
              />
              <DivDownload
                link="https://play.google.com/store/apps/details?id=com.salsafitt&hl=es&gl=US"
                icon="googleplay"
              />
            </section>
          </section>
        </section>
        <section className="footer_copyright_container">
          <div className="footer_divisor" />
          <p className="footer_text_lightColor">
            Copyright ©2022 | StrengthFitness.com
          </p>
        </section>
      </div>
    </>
  );
};

export default Footer;
