import React from "react";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { sendEvent } from "../../utils/Events";
import "./CardDance.css";


const CardDance = ({ data }) => {

  const verVideo = () => {
    sendEvent("modal", { type: data.video });
  };

  return (
    <div className="dance_card_continainer">
      <div className="dance_card_img_container">
        <img
          className="dance_card_img_img"
          src={data.thumb}
          alt={data.title}
        />
      </div>
      <div className="dance_card_info_container">
        <section className="dance_card_info_title_container">
          <h3>{data.title}</h3>
          <h5>{data.profesor}</h5>
        </section>
        <section className="dance_card_info_btn_container">
          <button className="dance_card_info_btn_btn" onClick={() => verVideo() }>
            <MdOutlineKeyboardArrowRight size={25} className="dance_card_info_btn_icon" />
          </button>
        </section>
      </div>
    </div>
  );

};

export default CardDance;